import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
    return () => overlay.scrollStrategies.block();
}

@Directive({
    selector: '[componentFactory]',
    standalone: true,
    providers: [
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: scrollFactory,
            deps: [Overlay],
        },
        {
            provide: MAT_TOOLTIP_SCROLL_STRATEGY,
            useFactory: scrollFactory,
            deps: [Overlay],
        },
    ],
})
export class ComponentFactoryDirective implements OnChanges, OnDestroy {
    @Input() config: ComponentFactoryConfig;

    private componentRef: ComponentRef<any>;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        // Check if config has changed
        if (changes['config']) {
            this.config = changes['config'].currentValue;
            if (this.config) {
                this.loadComponent();
            } else {
                this.clearComponent();
            }
        }
    }

    ngOnDestroy(): void {
        this.clearComponent();
    }

    /**
     * Loads the component provided in the config and injects the given data into the new component.
     */
    private loadComponent(): void {
        // Destroy the existing component if it exists

        this.clearComponent();

        // Create the component using the provided factory
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            this.config.component as any
        );

        // Clear the container before inserting the new component
        this.viewContainerRef.clear();
        this.componentRef = this.viewContainerRef.createComponent(componentFactory);

        // Pass data into the custom component instance
        this.config.data.forEach((value, key) => {
            this.componentRef.instance[key] = value;
        });

        this.config.reference = this.componentRef.instance;

        // Detect changes to update the newly created component with initial inputs
        this.componentRef.changeDetectorRef.detectChanges();
    }

    /**
     * Clears the existing component from the view container.
     */
    private clearComponent(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
        this.viewContainerRef.clear();
    }
}

/**
 * The configuration for a component that should be created by the ComponentFactoryDirective.
 * Any data can be passed into the desired component using the 'data: Map<string, any>' property,
 * where the string is the name of the property in the component.
 */
export interface ComponentFactoryConfig {
    component: any;
    data: Map<string, any>;
    reference?: any;
}
