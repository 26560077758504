import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SaveButtonService {
    private _saveFinished: Subject<void> = new Subject();

    private _hasUnsavedChanges: WritableSignal<boolean> = signal(false);

    private _continueWithoutSaving: Subject<boolean> = new Subject();

    public get hasUnsavedChanges(): Signal<boolean> {
        return this._hasUnsavedChanges.asReadonly();
    }
    public set hasUnsavedChanges(value: boolean) {
        this._hasUnsavedChanges.set(value);
    }

    public get continueWithoutSaving(): Observable<boolean> {
        return this._continueWithoutSaving.asObservable();
    }

    public set continueWithoutSaving(value: boolean) {
        this._continueWithoutSaving.next(value);
    }

    public get isSaveFinished() {
        return this._saveFinished;
    }
    public setSaveFinished(): void {
        this._saveFinished.next();
        this._saveFinished.complete();
        this._saveFinished = new Subject();
    }
}
