import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    Injector,
    Input,
    OnInit,
    Renderer2,
    WritableSignal,
    effect,
} from '@angular/core';
import { SaveButtonService } from './save-button.service';

@Directive({
    selector: '[saveButton]',
    standalone: true,
})
export class SaveButtonDirective implements OnInit {
    private warnActive = false;

    @Input() showWarn: WritableSignal<boolean> | undefined;
    @Input() mode: 'badge' | 'bgColour' = 'badge';
    @Input() automaticDisable: boolean = false;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private inject: Injector,
        private cdr: ChangeDetectorRef,
        private _saveButtonService: SaveButtonService
    ) {}

    ngOnInit(): void {
        effect(
            () => {
                if (
                    this.showWarn &&
                    this._saveButtonService.hasUnsavedChanges() &&
                    !this.warnActive
                ) {
                    if (this.mode === 'badge') {
                        this.renderer.addClass(this.el.nativeElement, 'badge-active');
                    } else {
                        this.renderer.addClass(this.el.nativeElement, 'bg-primary-200');
                    }
                    this.warnActive = true;
                    this._saveButtonService.hasUnsavedChanges = true;
                } else if (
                    this.showWarn &&
                    !this._saveButtonService.hasUnsavedChanges() &&
                    this.warnActive
                ) {
                    if (this.mode === 'badge') {
                        this.renderer.removeClass(this.el.nativeElement, 'badge-active');
                    } else {
                        this.renderer.removeClass(this.el.nativeElement, 'bg-primary-200');
                    }
                    this.warnActive = false;
                    this._saveButtonService.hasUnsavedChanges = false;
                }

                // Apply 'disabled' class instead of setting 'disabled' attribute
                if (this.automaticDisable) {
                    if (!this.warnActive) {
                        this.renderer.addClass(this.el.nativeElement, 'disabled');
                    } else {
                        this.renderer.removeClass(this.el.nativeElement, 'disabled');
                    }
                }

                this.cdr.detectChanges();
            },
            {
                injector: this.inject,
                allowSignalWrites: true,
            }
        );
    }
}
