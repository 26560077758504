import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    InputSignal,
    OnDestroy,
    OnInit,
    WritableSignal,
    input,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { HasRoleAndPermissionDirective } from 'app/core/auth/roles-and-permissions/directive/has-role-and-permission.directive';
import { SaveButtonDirective } from 'app/core/save-button/save-button.directive';
import { ActionDescriptor, ButtonType } from '../dialog-builder.models';

@Component({
    selector: 'action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        HasRoleAndPermissionDirective,
        SaveButtonDirective,
        MatBadgeModule,
        MatMenuModule,
        TranslocoModule,
    ],
})
export class ActionBarComponent implements OnInit, OnDestroy {
    actionDescriptors: InputSignal<ActionDescriptor[]> = input([]);
    @Input() executeActionSignal: WritableSignal<string>;
    @Input() dropdownMode: boolean = false;
    @Input() selectionMode: boolean = false;
    @Input() preselect: string;

    lastClicked: string;

    constructor() {}

    ngOnInit(): void {
        if (this.preselect) {
            this.doAction(this.actionDescriptors().find(ad => ad.code === this.preselect));
        }
    }
    ngOnDestroy(): void {
        if (this.actionDescriptors) {
            this.actionDescriptors()?.forEach(ad => {
                ad.class = ad.class?.replace('active', '');
            });
        }
    }

    /**
     * Sets the code of the action to the given signal.
     * @param action
     */
    doAction(action: ActionDescriptor): void {
        const toSet = this.executeActionSignal() === action.code ? null : action.code;

        this.executeActionSignal.set(toSet);
        if (this.selectionMode) {
            const descriptor1 = this.actionDescriptors().find(ad => ad.code === this.lastClicked);
            const descriptor2 = this.actionDescriptors().find(ad => ad.code === toSet);

            if (descriptor1) {
                descriptor1.class = descriptor1.class.replace('active', '');
            }
            if (descriptor2) {
                if (descriptor2.class) {
                    descriptor2.class += ' active';
                } else {
                    descriptor2.class = 'active';
                }
            }

            this.lastClicked = toSet;
        }
    }

    buttonType(): typeof ButtonType {
        return ButtonType;
    }

    showWarn(): boolean {
        if (!this.actionDescriptors) {
            return false;
        }
        for (let index = 0; index < this.actionDescriptors.length; index++) {
            const action = this.actionDescriptors[index];

            if (action.showWarn) {
                return true;
            }
        }
        return false;
    }
}
