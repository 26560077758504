import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../../auth.service';

@Directive({
    selector: '[HasRoleAndPermission]',
    standalone: true,
})
export class HasRoleAndPermissionDirective implements OnInit {
    @Input() hasPermissionsAndRoles: string[];

    constructor(private el: ElementRef, private renderer: Renderer2, private auth: AuthService) {}

    ngOnInit(): void {
        if (this.hasPermissionsAndRoles) {
            const answer = this.auth.hasPermissionsOnRoles(this.hasPermissionsAndRoles);

            if (!answer) {
                this.renderer.removeChild(
                    this.el.nativeElement.parentElement,
                    this.el.nativeElement
                );
            }
        }
    }
}
