import { WritableSignal } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

/**
 * The configuration given to the openDialog method of the DialogBuilderService.
 * Use the descriptor to customize the dialog.
 * The config is the default Angular Material Dialog config.
 */
export interface DialogBuilderConfig {
    descriptor?: DialogBuilderDescriptor;
    config?: MatDialogConfig<any>;
}

/**
 * The descriptor of the dialog. A dialog has a header (mostly the title and a close button),
 * a content which is shown in the middle, and actions which are the buttons on the bottom.
 * Additionally, the size of the dialog can be set via dialogSize.
 */
export interface DialogBuilderDescriptor {
    header: {
        title?: string;
        showCloseButton?: boolean;
        componentConfig?: DialogComponent;
    };
    content: {
        description?: string;
        componentConfig?: DialogComponent;
        icon?: {
            show: boolean;
            name: string;
            color: string;
        };
    };
    actions: {
        dialogActions?: ActionDescriptor[];
        componentConfig?: DialogComponent;
    };
    executeActionSignal?: WritableSignal<string>;
    dialogSize?: 's' | 'm' | 'l' | 'xl' | 'xxl' | 'fullscreen';
}

/**
 * Custom component and it's inputs.
 */
export interface DialogComponent {
    component: any;
    componentData?: any;
}

/**
 * The type of buttons that can appear on the actions bar.
 */
export enum ButtonType {
    simple = 'button',
    raised = 'raised-button',
    flat = 'flat-button',
    stroked = 'stroked-button',
    fab = 'fab',
    miniFab = 'mini-fab',
    icon = 'icon-button',
}

/**
 * The descriptor of the actions, alias buttons.
 */
export type ActionDescriptor =
    | ButtonActionDescriptor
    | RaisedButtonActionDescriptor
    | FlatButtonActionDescriptor
    | StrokedButtonActionDescriptor
    | FabActionDescriptor
    | MiniFabActionDescriptor
    | IconButtonActionDescriptor;

export type BaseButtonActionDescriptor<T = `${ButtonType}`> = {
    style: T;
    code: string;
    title: string;
    icon?: string;
    iconPosition?: 'pre' | 'post';
    color?: string | 'primary' | 'secondary' | 'warn';
    class?: string;
    manualClose?: boolean;
    tooltip?: string;
    hasPermissionsAndRoles?: string[];
    showWarn?: boolean;
    automaticDisableForSave?: boolean;
    dismiss?: boolean;
    hide?: boolean;
};

export type ButtonActionDescriptor = BaseButtonActionDescriptor<`${ButtonType.simple}`>;

export type RaisedButtonActionDescriptor = BaseButtonActionDescriptor<`${ButtonType.raised}`>;

export type FlatButtonActionDescriptor = BaseButtonActionDescriptor<`${ButtonType.flat}`>;

export type StrokedButtonActionDescriptor = BaseButtonActionDescriptor<`${ButtonType.stroked}`>;

export type FabActionDescriptor = BaseButtonActionDescriptor<`${ButtonType.fab}`>;

export type MiniFabActionDescriptor = BaseButtonActionDescriptor<`${ButtonType.miniFab}`>;

export type IconButtonActionDescriptor = Omit<
    BaseButtonActionDescriptor<`${ButtonType.icon}`>,
    'title'
>;
